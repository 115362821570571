//*------------------------------------*\
//    $EXTRA LARGE BREAKPOINT
//*------------------------------------*/

@media screen and (min-width: '#{$x-large-breakpoint}px') {
     .news {
		 .module-content {
			ol {
				
				li {
					min-height: calc(25vw);
					padding-top: 0;
					
					p {
						font-size: $scale05;
					}
				
					time {
						font-size: $scale05;
					}
				}
			}
		}
	}
}
