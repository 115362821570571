//*------------------------------------*\
//    $SMALL BREAKPOINT
//*------------------------------------*/

.bio {
	.module-content {
		padding: calc(#{$default-module-side-padding} * 2);
		padding-bottom: calc(6vw - 1rem);

		.bio-details {
			display: flex;
			flex-direction: column;

			.headline {
				order: 2;
			}

			img { 
				order: 1;
				width: 100%;
				margin: 0 0 1rem 0;
			}
		}

		.body-copy {
			color: white;
			margin-top: 1rem;
			font-size: $scale05;

			p {
				line-height: 1.3;

				a {
					color: currentColor;
				}
			}
		}
	}
	
	&:nth-child(3n+1):not(:first-child) {
    	.module-content {
    		background-color: $tertiaryDarker;
    	}
    }
    
    &:nth-child(3n+2) {
		.module-content {
    		background-color: $secondaryDarker;
    	}
    }
    
    &:nth-child(3n+3) {
		.module-content {
    		background-color: $quaternaryDarker;
    	}
    }	
}