//*------------------------------------*\
//    $LARGE BREAKPOINT
//*------------------------------------*/

@media screen and (min-width: '#{$large-breakpoint}px') {
    .header {	
		.site-title {
		  color: $white;
		  font-size: calc(8.5vw + 1rem);
		  font-family: $display;
		}

		.page-title {
		  color: $white;
		  font-size: calc(5vw + 1rem);
		  font-family: $display;
		}	
	}
}
