//*------------------------------------*\
//    $EXTRA LARGE BREAKPOINT
//*------------------------------------*/

@media screen and (min-width: '#{$x-large-breakpoint}px') {
    .module {
        [role="button"], .ajax, .cta {
            font-size: $scale04;
        }
    }
}
