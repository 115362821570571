//*------------------------------------*\
//    $SMALL BREAKPOINT
//*------------------------------------*/

.statement {
	.module-content {
		background-color: red;
		padding: calc(#{$default-module-side-padding} * 2);

		.headline {
			font-size: $scale07;
		}
		
		.body-copy {
			@include body-copy-headline($scale05);
			font-weight: bold;
			line-height: $headline;
			overflow: hidden;
			
			img { 
				width: 20vw;
				float: left;
				margin: 1rem 2rem;
			}
		}
	}

	&:nth-child(3n+1):not(:first-child) {
    	.module-content {
    		background-color: $tertiary;
    	}
    }
    
    &:nth-child(3n+2) {
		.module-content {
			background-color: $secondary;
    	}
    }
    
    &:nth-child(3n+3) {
		.module-content {
			background-color: $quaternary;
    	}
    }	
}