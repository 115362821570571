//*------------------------------------*\
//    $COLOR PALETTE
//    http://www.colorschemer.com/schemes/viewscheme.php?id=10772
//*------------------------------------*/

$white: rgb(255,255,255);

$gray: rgb(141,141,141);
$grayText: rgb(60,60,60);
$grayDarkest: rgb(50,50,50);

$black: $grayDarkest;

$error: red;

// Pink
$primary: #D04B8E;
$primaryLighter: lighten($primary, 30%);
$primaryDarker: darken($primary, 15%);

// Red
$secondary: #C23427;
$secondaryLighter: lighten($secondary, 30%);
$secondaryDarker: darken($secondary, 15%);

// Orange
$tertiary: #D38C3A;
$tertiaryLighter: lighten($tertiary, 30%);
$tertiaryDarker: darken($tertiary, 15%);

// Blue
$quaternary: #6ACBBE;
$quaternaryLighter: lighten($quaternary, 30%);
$quaternaryDarker: darken($quaternary, 30%);

// Purple
$quinternary: #B144C6;
$quinternaryLighter: lighten($quinternary, 30%);
$quinternaryDarker: darken($quinternary, 15%);