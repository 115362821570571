//*------------------------------------*\
//    $TRANSITIONS
//*------------------------------------*/

[class^="animated-"], [class*=" animated-"] {
    opacity: 0;
    transform: translateY(2%);
    transition: transform 0.3s 0s $bezier, opacity 0.3s 0s $bezier;

    [data-visible="true"].build-on-scroll &, [data-has-animated].build-on-scroll &, .build-on-hover:hover & {
        opacity: 1;
        transform: translateY(0);
    }
}

@for $i from 1 through 20 {
    .animated-#{$i} {
        transition-delay: 0.05s * ($i + 1);
    }
}

.dark-hover {
    .bg-zoom {
        transition: transform 0.5s $bezier;

        &:before {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $black;
            opacity: 0;
            transition: opacity 0.5s $bezier;
            content: '';
        }
    }

    .dark-overlay {
        position: absolute !important;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100%;
        background-color: rgba(0, 0, 0, 0);
        z-index: $z-index-400;
        transition: background-color 0.5s 0s $bezier;
    }

    &:hover, &:focus {
        .bg-zoom {
            transform: scale(1.075);

            &:before {
                opacity: 0.2;
                transition: opacity 0.5s 0s $bezier;
            }
        }

        .dark-overlay {
            background-color: rgba(0, 0, 0, 0.12);
        }
    }
}

.arrow-hover {
    .arrow {
        opacity: 0;
        animation: 0.2s $bezier 0s forwards arrow-slideout;
    }

    &:hover, &:focus {
        .arrow {
            opacity: 1;
            animation: 0.2s $bezier 0s forwards arrow-slidein;
        }
    }
}
