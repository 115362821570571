//*------------------------------------*\
//    $SMALL BREAKPOINT
//*------------------------------------*/

.news {
	.module-content {
		padding-left: 0;
		padding-right: 0;
		padding-bottom: calc(6vw - 1rem);
		
		header {
			background-color: red;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: flex-end;
			padding: $default-module-side-padding;
			margin-bottom: 3vw;
			width: 100%;
			
			a {
				@include display-headline($scale04);
			}
		}
		
		ol {
			
			li {
				display: block;
				width: 100%;
				height: auto;
				margin: 0 1.5vw 3vw 0;
				position: relative;
				
				.news-content {
					position: relative;
					padding: 1.5vw;
					width: 100%;
					height: auto;
					background-color: blue;
					border-width: $default-module-side-padding;
					
					a {
						font-style: italic;
						font-weight: bold;
					}
				}
				
				strong {
					@include body-copy-headline($scale07);
					font-weight: bold;
				}
				
				p {
					@include body-copy-light($scale05);
				}
				
				time {
					display: block;
					@include body-copy-light($scale04);
					font-style: italic;
				}
				
			}
		}
	}
	
	&:nth-child(3n+1):not(:first-child) {
    	.module-content {
    		header {
    			background-color: $tertiaryDarker;
    		}

    		ol {
			
				li {
    				.news-content {
    					background-color: white;
    					border: $default-module-side-padding solid $tertiary;
    					color: $tertiaryDarker;
    					
    					a, time {
    						color: $tertiaryDarker;
    					}
    				}
    			}
    		}
    	}
    }
    
    &:nth-child(3n+2) {
		.module-content {
			header {
    			background-color: $quinternaryDarker;
    		}

    		ol {
			
				li {
    				.news-content {
    					background-color: white;
    					border: $default-module-side-padding solid $quinternary;
    					color: $quinternaryDarker;
    					
    					a, time {
    						color: $quinternaryDarker;
    					}
    				}
    			}
    		}
    	}
    }
    
    &:nth-child(3n+3) {
		.module-content {
			header {
    			background-color: $quaternaryDarker;
    		}

    		ol {
			
				li {
    				.news-content {
    					background-color: white;
    					border: $default-module-side-padding solid $quaternary;
    					color: $quaternaryDarker;
    					
    					a, time {
    						color: $quaternaryDarker;
    					}
    				}
    			}
    		}
    	}
    }	
}