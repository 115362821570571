//*------------------------------------*\
//    $MIXINS
//*------------------------------------*/

// ======================================
// Inline Breakpoints
// ======================================

@mixin breakpoint($breakpoint) {
    @if $breakpoint == s {
        @media (min-width: #{$small-breakpoint}px) { @content; }
    }
    @if $breakpoint == m {
        @media (min-width: #{$medium-breakpoint}px) { @content; }
    }
    @if $breakpoint == l {
        @media (min-width: #{$large-breakpoint}px) { @content; }
    }
    @if $breakpoint == xl {
        @media (min-width: #{$x-large-breakpoint}px) { @content; }
    }
    @if $breakpoint == xxl {
        @media (min-width: #{$xx-large-breakpoint}px) { @content; }
    }
}

// ======================================
// Vertical Align
// ======================================

@mixin vertical-align {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

// ======================================
// Horizontal Align
// ======================================

@mixin horizontal-align {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

@mixin vertical-horizontal-align {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

// ======================================
// Visually Hidden
//
// Visually hide the element from the
// screen but still have it accessible
// via screenreaders
//
// ======================================
@mixin isVisuallyHidden() {
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    position: absolute;
    clip: rect(0 0 0 0);
    overflow: hidden;
}

// ======================================
// Module Spacing
// ======================================

@mixin module-spacing($top: 1, $bottom: 1, $left: $default-module-side-padding, $right: $default-module-side-padding, $type: padding) {
    #{$type}: {
        top: calc(1.5vw * #{$top});;
        bottom: calc(1.5vw * #{$bottom});
        left: $left;
        right: $right;
    }
}

// ======================================
// Ellipsis
// ======================================

@mixin ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

// ======================================
// Column-Based Layout
// ======================================

@mixin col($style, $content-col, $grid-col: 12) {
    @if $style == width {
        width: calc(100% / #{$grid-col} * #{$content-col});
    }
    @if $style == margin {
        margin-left: calc(100% / #{$grid-col} * #{$content-col});
    }
}

@mixin col-padding {
    padding: {
        left: 1rem;
        right: 1rem;
    }
}
