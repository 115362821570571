//*------------------------------------*\
//    $LARGE BREAKPOINT
//*------------------------------------*/

@media screen and (min-width: '#{$large-breakpoint}px') {
	.project {
		.module-content {
			.headline {
				font-size: $scale08;
			}
			
			.project-details {
				ul {
				
					li {
						font-size: $scale05;
					}
				}
			}
		
			
		}
	}
}
