//*------------------------------------*\
//    $TYPOGRAPHIC VARIABLES
//*------------------------------------*/

$copy-font-name:'Gilroy Light';
$copy-fonts: roman bold;
$copy-fonts-italic: italic;
$copy-font-weights: font-weight-regular font-weight-bold;
$copy-font-weights-italic: font-weight-regular;

$display-font-name: 'Gilroy Extra Bold';
$display-fonts: regular;
$display-font-weights: font-weight-regular;

/* Font weights */

$font-weight-regular:400;
$font-weight-medium: 500;
$font-weight-bold:700;

// Default font size
$font-size-default: 16px;

// Font Stack
$serif: $copy-font-name;
$display: $display-font-name;
$sans: $display-font-name, Helvetica, Arial, sans-serif;


// Type scale "Le Corbusier" taken from http:/lamb.cc/typograph/
$scale01: 0.5rem;
$scale02: 0.6154rem;
$scale03: 0.8077rem;
$scale04: 1rem;
$scale05: 1.3077rem;
$scale06: 1.6154rem;
$scale07: 2.1154rem;
$scale7half: 2.3654rem;
$scale08: 2.6154rem;
$scale09: 3.4231rem;
$scale10: 4.2308rem;
$scale11: 5.5385rem;
$scale12: 6.8462rem;

// Line Heights
$solid-serif: 1;
$solid-sans: $solid-serif;
$title-serif: $solid-serif;
$headline: 1.2;
$title-sans: 1.4;
$prose-serif: 1.5;
$prose-sans: $prose-serif;

// Letter Spacing
$spacing-serif-tighter: -0.04em;
$spacing-serif-tight: -0.02em;
$spacing-serif: 0em;
$spacing-serif-large: 0.015em;
$spacing-serif-mega: 0.1em;
$spacing-sans-tight: -0.02em;
$spacing-sans: 0em;
$spacing-sans-large: 0.02em;
$spacing-sans-mega: 0.05em;
