//*------------------------------------*\
//    $SMALL BREAKPOINT
//*------------------------------------*/

// Activate ligatures

body {
    font-family: $copy-font-name;
    text-rendering: optimizeLegibility;
    font-variant-ligatures: common-ligatures;
    font-feature-settings: "kern";
    font-kerning: normal;
    -webkit-font-smoothing: antialiased;
}

// Link Styling
.link {
    @include link();
}

// Module defaults

.headline {
    @include display-headline($scale07);
}

.subhead {
    @include display-headline($scale06);
    line-height: 1.4;
}

.body-copy-bold {
    @include body-copy-bold($scale04);
}
