//*------------------------------------*\
//    $SMALL BREAKPOINT
//*------------------------------------*/

.header {
    margin: 25vh auto 0 auto;
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
	width: 100%;
	height: 100vh;
	
	.site-title {
	  color: $white;
	  font-size: calc(16.5vw + 1rem);
	  font-family: $display;
	  text-shadow: 0px 0px 4px rgba(0,0,0,0.51);
	}

	.page-title {
	  color: $white;
	  font-size: calc(8vw + 1rem);
	  font-family: $display;
	  text-shadow: 0px 0px 2px rgba(0,0,0,0.51);
	}	
}