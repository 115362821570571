//*------------------------------------*\
//    $SMALL BREAKPOINT
//*------------------------------------*/

.nav-trigger {
	position: fixed;
	top: 3vw;
    right: 3vw;
	z-index: 10000;
	width: 100%;
	padding: 0;
	margin: 0;
}

.navTrigger {
	background-color: $grayText;
    padding: 0.5vw;
    margin: 0;
    display: inline;
    cursor: pointer;
    transition-property: opacity, letter-spacing;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    color: $white;
    position: absolute;
    top: 0;
    right: 0;
    @include display-headline($scale03);
	letter-spacing: 0.02em;
	text-decoration: none;
	text-transform: uppercase;
	transition: letter-spacing 0.5s $bezier-nav;

    // Focus Styles
    &:focus {
        outline: none;
    }
    
    &:hover {
			letter-spacing: 0.075em;
		}

    &-instructions {
        @include isVisuallyHidden();
    }
    
    &-label {
		padding: 0;
    }

	&:hover:not(.isOpen) &-inner {
		
	}

	&.primary {
		color: $primary;
	}

	&.secondary {
		color: $secondary;
	}

	&.tertiary {
		color: $tertiary;
	}
	
	&.quaternary {
		color: $quaternary;
	}

	// Toggle `isOpen` class with JS to animate
	&.isOpen {
		background-color: transparent;
		opacity: 1;
	
		&.primary {
			color: $white;
		}

		&.secondary {
			color: $white;
		}

		&.tertiary {
			color: $white;
		}
		
		&.quaternary {
			color: $white;
		}
		
		.navTrigger-instructions {
			width: auto;
			height: auto;
			clip: auto;
			margin: 0;
			position: relative;
		}
    }
}
