//*------------------------------------*\
//    $LARGE BREAKPOINT
//*------------------------------------*/

@media screen and (min-width: '#{$large-breakpoint}px') {
    .site-navigation {
        &.isOpen {
        	.site-nav {
				.inner-nav {
					flex-direction: row;
					padding-top: 10vw;
					overlfow: hidden;
				}
            }
        }
        
        .links {
        	font-size: calc(#{$scale09} + 1vw);
        }
    }
}
