//*------------------------------------*\
//    $SMALL BREAKPOINT
//*------------------------------------*/

.link-list {
	background-color: #ff006e;
	margin: $default-module-side-padding auto;
	padding-left: 0;
	padding-right: 0;
	padding-bottom: calc(6vw - 1rem);
	
	header {
		color: $white;
		font-size: calc(4.5vw + 1rem);
		font-family: $display;
		text-shadow: 0px 0px 4px rgba(0,0,0,0.51);
	}
	
	ol {
		
		li {
			background-color: $white;
			display: block;
			width: 100%;
			height: auto;
			margin: 48px auto;
			padding: 1.5vw;
			position: relative;
			@include display-headline($scale05);
			
			strong {
				color: #CC0058;
				display: block;
			}
				
			a {
				color: #fb5607;
				display: block;
				margin-top: 1.5vw;
				font-weight: bold;
				
				&:first-of-type {
					margin-top: 0;
				}
				
				&:hover {
					color: #3a86ff;
				}
				
				&:active {
					color: #ffbe0b;
				}
				
				&:visited {
					color: #8338ec;
				}
			}
			
			strong + a:first-of-type {
				margin-top: 1.5vw; 
			}
		}
	}
}