//*------------------------------------*\
//    $FORM
//*------------------------------------*/

%input {
    color: currentColor;
    appearance: none;
    -webkit-appearance: textfield;
    -webkit-box-sizing: content-box;
    -webkit-appearance: none;
    font-family: $display;
    font-size: $scale04;
    letter-spacing: $spacing-sans;
    line-height: 1.3;
    border: none;
    padding: 0 1.5em 0 0;
    position: relative;
    box-sizing: border-box;
    width: 100%;
    background-color: transparent;
    background-position: right center;
    background-repeat: no-repeat;
    background-size: 0.8em 0.8em;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-radius: 0;
    z-index: 1;
    opacity: 0.4;
    box-shadow: none;
    transition: opacity 0.1s;
    overflow: hidden;
    resize: none;

    &:hover {
        opacity: 1;
    }

    &:focus {
        opacity: 1;
        outline: 0;
    }

}

%label-display {
    display: none;
}

.form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .headline, .body-copy {
    	width: 100%;
    }
    
    &.module {
        padding: $default-module-side-padding;
        background-color: $primary;
    }
    
    &-field {
        position: relative;
        width: 100%;
        margin-top: 3em;
    }

    // Label
    label {
        @extend %label-display;
    }
    
    // Input - text
    input {
        @extend %input;
    }

    // Select
    select {
        @extend %input;
        background: url('../img/form-select.svg') no-repeat right 6px;
        background-size: 0.8em auto;

		option {
			padding: 0;
			margin: 0;
		}
		
		.option-hidden {
			display: none;
		}

		&::-ms-expand {
        	display: none;
    	}
	}

    // Text Area
    textarea {
        @extend %input;
        resize: none;
        overflow: hidden;
    }
    
    // Text
    text {
        text-align: center;
    }
    
    // Error message
    &-field-error {
        display: none;
    }
    &-error {
        padding: 3px 0 0 2.2em;
        color: $error;
        font-size: $scale03;
        line-height: 1.2;
        position: relative;
        margin-bottom: 1em;
        
        // Had to use :before because Safari was ignoring bg position
        &:before {
            content: '';
            position: absolute;
            width: 40px;
            height: 40px;
            left: 0;
            top: -4px;
        }
    }
    
    .form-field-messages {
        padding: 3px 0 0 2.2em;
        color: $white;
        font-size: $scale04;
        line-height: 1.2;
        position: relative;
        margin-bottom: 1em;
        text-align: center;
    }
}

.disabled {
    display: none;
}

form button, [role="button"], form input[type=submit], .ajax, .cta {
    background: transparent;
    border-color: $white;
    color: $white;
    border-style: solid;
    border-width: 2px;
    border-radius: 0;
    font-family: $display;
    font-size: $scale03;
    font-weight: 700;
    letter-spacing: $spacing-sans;
    line-height: $solid-serif;
    padding: 1.5em 6em;
    text-align: center;
    text-transform: uppercase;
    transition: border-color .1s, background-color .1s, color .1s;
    -webkit-appearance: none;

    &:hover {
        background-color: rgba(255,255,255,0.25);
    }
    
    &[type=reset] {
    	border: none;
    	
    	&:hover {
        	background-color: transparent;
    	}
    }
}

.buttons {
	margin-top: 2rem;
	display: flex;
	justify-content: flex-start;
	
	button, [type=submit] {
		margin-right: 2rem;
	}
}

form input[type=submit] {
	width: auto;
	margin-top: 2rem;
	
	&:hover {
		cursor: pointer;
	}
}


form {
    @include form-styles($white, $white);
    background: transparent;
}

::-webkit-input-placeholder { /* WebKit, Blink, Edge */
	color:    $white;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
   color:    $white;
   opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
   color:    $white;
   opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color:    $white;
}

.alert.red {
	margin-top: 2rem;
	color: $error;
	
	p {
		line-height: 1.5rem;
	}
}


