//*------------------------------------*\
//    $PADDING VARIABLES
//*------------------------------------*/


$default-module-top-padding: 20vw;
$default-module-top-padding-m: 15vw;
$default-module-top-padding-lg: 7.5vw;
$default-module-top-padding-xl: 10vw;

$default-module-side-padding: 1.5vw;
$default-module-side-padding-half: calc(1.5vw/2);
