//*------------------------------------*\
//    $EXTRA LARGE BREAKPOINT
//*------------------------------------*/

@media screen and (min-width: '#{$x-large-breakpoint}px') {
     .bio {
		.module-content {
			.body-copy {
				strong {
					@include body-copy-headline($scale06);
					font-weight: bold;
				}
				
				p {
					font-size: $scale06;
				}
			
				time {
					font-size: $scale06;
				}
			}
		}
	}
}
