//*------------------------------------*\
//    $EXTRA LARGE BREAKPOINT
//*------------------------------------*/

@media screen and (min-width: '#{$x-large-breakpoint}px') {
    .site-navigation {
        .links {
            font-size: $scale10;
            
            &.secondary {
        		font-size: calc(#{$scale06} + 1vw);
        	}
        }
    }
}
