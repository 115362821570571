//*------------------------------------*\
//    $LARGE BREAKPOINT
//*------------------------------------*/

@media screen and (min-width: '#{$medium-breakpoint}px') {
	.news {
		.module-content {
			ol {
				width: 100%;
				
				li {
					min-height: calc(39vw);
					padding-top: 0;

					strong {
						@include body-copy-headline($scale05);
						font-weight: bold;
					}
					
					p {
						font-size: $scale05;
					}
				
					time {
						font-size: $scale05;
					}
				}
			}
			
		}
	}
}
