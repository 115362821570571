//*------------------------------------*\
//    $MEDIUM BREAKPOINT
//*------------------------------------*/

@media screen and (min-width: '#{$medium-breakpoint}px') {
    .site-navigation {
        .site-nav {
        	.inner-nav {
            	align-items: center;
            }
        }
        
        .links {
        	line-height: 1;
			font-size: calc(#{$scale08} + 1vw);
            
            &.secondary {
        		font-size: calc(#{$scale05} + 1vw);
        	}
        	
        	&.social {
        		width: 100%;
        		flex-wrap: nowrap;
        	}
        }
    }
}
