/* generated */
@font-face {
  font-family: icons;
  src: url("../fonts/icons.eot");
  src: url("../fonts/icons.eot?#iefix") format("embedded-opentype"), url("../fonts/icons.woff") format("woff"), url("../fonts/icons.ttf") format("truetype"), url("../fonts/icons.svg#icons") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"]:before, [class*=" icon-"]:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  content: "";
  font-family: 'icons';
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: none;
  text-transform: none; }

.icon-amazon:before {
  content: "\EA01"; }

.icon-bandcamp:before {
  content: "\EA02"; }

.icon-instagram:before {
  content: "\EA03"; }

.icon-itunes:before {
  content: "\EA04"; }

.icon-soundcloud:before {
  content: "\EA05"; }

.icon-spotify:before {
  content: "\EA06"; }

.icon-youtube:before {
  content: "\EA07"; }

/* base */
/* Font weights */
html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

body {
  margin: 0;
  font: 16px/1 "Gilroy Light"; }

h1, h2, h3, h4, p, blockquote, figure, ol, ul {
  margin: 0;
  padding: 0; }

main, li {
  display: block; }

ol, ul {
  list-style: none; }

h1, h2, h3, h4 {
  font-size: inherit; }

strong {
  font-weight: bold; }

a, [role="button"], .ajax, .cta {
  color: inherit; }

a {
  text-decoration: none; }

button {
  overflow: visible;
  border: 0;
  font: inherit;
  -webkit-font-smoothing: inherit;
  letter-spacing: inherit;
  background: none;
  cursor: pointer; }

::-moz-focus-inner {
  padding: 0;
  border: 0; }

:focus {
  outline: 0; }

img {
  max-width: 100%;
  height: auto;
  border: 0; }

blockquote, q {
  quotes: none; }

/*
 * Using full hand notation to use animation-delay elsewhere
 */
/* Fonts */
@font-face {
  font-family: "Gilroy Extra Bold";
  font-weight: 400;
  font-style: normal;
  unicode-range: U+000-5FF;
  /* Download only latin glyphs */
  src: local("Gilroy Extra Bold"), url("/user/themes/loveintheruins/fonts/Gilroy-ExtraBold.woff2") format("woff2"), url("/user/themes/loveintheruins/fonts/Gilroy-ExtraBold.woff") format("woff"); }

@font-face {
  font-family: "Gilroy Light";
  font-weight: 400;
  font-style: normal;
  unicode-range: U+000-5FF;
  /* Download only latin glyphs */
  src: local("Gilroy Light"), url("/user/themes/loveintheruins/fonts/Gilroy-Light.woff2") format("woff2"), url("/user/themes/loveintheruins/fonts/Gilroy-Light.woff") format("woff"); }

body {
  font-family: "Gilroy Light";
  text-rendering: optimizeLegibility;
  font-variant-ligatures: common-ligatures;
  font-feature-settings: "kern";
  font-kerning: normal;
  -webkit-font-smoothing: antialiased; }

.link {
  display: inline;
  line-height: 1;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  color: #D04B8E;
  background: linear-gradient(to bottom, currentColor 25%, transparent 25%);
  background-repeat: no-repeat;
  background-position: right 1.1em;
  background-size: 0% .25em;
  transition: background-size 0.3s;
  text-decoration: underline; }
  .link:hover {
    background-image: linear-gradient(to bottom, currentColor 25%, transparent 25%);
    background-size: 100% 0.25em;
    background-position: left 1.1em; }
  .link.muted {
    color: #323232; }
  .link-primary {
    color: #D04B8E; }
  .link-secondary {
    text-decoration: none;
    position: relative;
    display: inline-block;
    cursor: pointer; }
  .link-arrow {
    margin-right: 1.5em;
    transition: 0s 0s; }
    .link-arrow:after {
      font-family: "icons";
      content: '\EA11';
      position: absolute;
      margin-left: 0.8em;
      font-size: 0.4em;
      vertical-align: middle;
      transition: margin-left 0.2s 0s cubic-bezier(0.32, 0.01, 0, 1); }
    .link-arrow:hover:after {
      margin-left: 1.4em; }
  .link:focus {
    outline: none; }

.headline {
  font-size: 2.1154rem;
  line-height: 1;
  letter-spacing: 0em;
  font-family: "Gilroy Extra Bold", Helvetica, Arial, sans-serif;
  font-weight: 700; }

.subhead {
  font-size: 1.6154rem;
  line-height: 1;
  letter-spacing: 0em;
  font-family: "Gilroy Extra Bold", Helvetica, Arial, sans-serif;
  font-weight: 700;
  line-height: 1.4; }

.body-copy-bold {
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0em;
  font-family: "Gilroy Light", Times, Georgia, serif;
  font-weight: 400;
  margin-bottom: 1em; }

@media screen and (min-width: 1600px) {
  .body-copy-bold {
    font-size: 1.3077rem; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes submit_span_1 {
  to {
    opacity: 0;
    transform: translate(-50%, 250%); } }

@keyframes submit_2 {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(359deg); } }

@keyframes submit_3 {
  0% {
    border-top: 2px #D04B8E solid;
    border-left: 0px #D04B8E solid;
    border-right: 0px #D04B8E solid;
    border-bottom: 0px #D04B8E solid; }
  20% {
    border-top: 2px #D04B8E solid;
    border-left: 2px #D04B8E solid;
    border-right: 2px #D04B8E solid;
    border-bottom: 2px #D04B8E solid;
    box-shadow: inset 0 0 0 0 #D04B8E; }
  100% {
    border-top: 1.75rem #D04B8E solid;
    border-left: 1.75rem #D04B8E solid;
    border-right: 1.75rem #D04B8E solid;
    border-bottom: 1.75rem #D04B8E solid; } }

@keyframes submit_4 {
  0% {
    border-bottom: 4px white solid;
    width: 0em;
    border-left: 4px white solid;
    height: 0em; }
  40% {
    border-bottom: 4px white solid;
    width: 0em;
    border-left: 4px white solid;
    height: 1em; }
  60% {
    border-bottom: 4px white solid;
    width: 0em;
    border-left: 4px white solid;
    height: 0.75em; }
  100% {
    border-bottom: 4px white solid;
    width: 1.5em;
    border-left: 4px white solid;
    height: 0.75em; } }

@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

/* page in/out animation */
@keyframes animateIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes animateOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/* base module styles */
.module[data-position] .module-content {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.03, 0.46, 0.31, 0.97);
  transform: translateY(0);
  opacity: 1;
  transition-duration: 0.75s; }

.module[data-position="below-viewport"] .module-content {
  transform: translateY(100%);
  transition-duration: 0s;
  opacity: 0; }

@keyframes fields_visible {
  0% {
    transform: translateY(100px);
    opacity: 0; }
  40% {
    transform: translateY(100px);
    opacity: 0; }
  80% {
    transform: translateY(-10px); }
  100% {
    transform: translateY(0);
    opacity: 1; } }

@keyframes fields_hide {
  0% {
    opacity: 1;
    transform: translateY(0); }
  99% {
    opacity: 0;
    transform: translateY(0); }
  100% {
    opacity: 0;
    transform: translateY(100px); } }

@keyframes linkunderlinein {
  0% {
    background-size: 0% 0.25em;
    background-position: left 1.1em; }
  100% {
    background-size: 100% 0.25em;
    background-position: left 1.1em; } }

@keyframes linkunderlineout {
  0% {
    background-size: 100% 0.25em;
    background-position: right 1.1em; }
  100% {
    background-size: 0% 0.25em;
    background-position: right 1.1em; } }

@keyframes navItemHover {
  0% {
    opacity: 0; }
  20% {
    opacity: 1; }
  80% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes heroFirstChild {
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

[class^="animated-"], [class*=" animated-"] {
  opacity: 0;
  transform: translateY(2%);
  transition: transform 0.3s 0s cubic-bezier(0.32, 0.01, 0, 1), opacity 0.3s 0s cubic-bezier(0.32, 0.01, 0, 1); }
  [data-visible="true"].build-on-scroll [class^="animated-"], [data-has-animated].build-on-scroll [class^="animated-"], .build-on-hover:hover [class^="animated-"], [data-visible="true"].build-on-scroll [class*=" animated-"], [data-has-animated].build-on-scroll [class*=" animated-"], .build-on-hover:hover [class*=" animated-"] {
    opacity: 1;
    transform: translateY(0); }

.animated-1 {
  transition-delay: 0.1s; }

.animated-2 {
  transition-delay: 0.15s; }

.animated-3 {
  transition-delay: 0.2s; }

.animated-4 {
  transition-delay: 0.25s; }

.animated-5 {
  transition-delay: 0.3s; }

.animated-6 {
  transition-delay: 0.35s; }

.animated-7 {
  transition-delay: 0.4s; }

.animated-8 {
  transition-delay: 0.45s; }

.animated-9 {
  transition-delay: 0.5s; }

.animated-10 {
  transition-delay: 0.55s; }

.animated-11 {
  transition-delay: 0.6s; }

.animated-12 {
  transition-delay: 0.65s; }

.animated-13 {
  transition-delay: 0.7s; }

.animated-14 {
  transition-delay: 0.75s; }

.animated-15 {
  transition-delay: 0.8s; }

.animated-16 {
  transition-delay: 0.85s; }

.animated-17 {
  transition-delay: 0.9s; }

.animated-18 {
  transition-delay: 0.95s; }

.animated-19 {
  transition-delay: 1s; }

.animated-20 {
  transition-delay: 1.05s; }

.dark-hover .bg-zoom {
  transition: transform 0.5s cubic-bezier(0.32, 0.01, 0, 1); }
  .dark-hover .bg-zoom:before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #323232;
    opacity: 0;
    transition: opacity 0.5s cubic-bezier(0.32, 0.01, 0, 1);
    content: ''; }

.dark-hover .dark-overlay {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100%;
  background-color: transparent;
  z-index: 400;
  transition: background-color 0.5s 0s cubic-bezier(0.32, 0.01, 0, 1); }

.dark-hover:hover .bg-zoom, .dark-hover:focus .bg-zoom {
  transform: scale(1.075); }
  .dark-hover:hover .bg-zoom:before, .dark-hover:focus .bg-zoom:before {
    opacity: 0.2;
    transition: opacity 0.5s 0s cubic-bezier(0.32, 0.01, 0, 1); }

.dark-hover:hover .dark-overlay, .dark-hover:focus .dark-overlay {
  background-color: rgba(0, 0, 0, 0.12); }

.arrow-hover .arrow {
  opacity: 0;
  animation: 0.2s cubic-bezier(0.32, 0.01, 0, 1) 0s forwards arrow-slideout; }

.arrow-hover:hover .arrow, .arrow-hover:focus .arrow {
  opacity: 1;
  animation: 0.2s cubic-bezier(0.32, 0.01, 0, 1) 0s forwards arrow-slidein; }

.form input, .form select, .form textarea {
  color: currentColor;
  appearance: none;
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
  -webkit-appearance: none;
  font-family: "Gilroy Extra Bold";
  font-size: 1rem;
  letter-spacing: 0em;
  line-height: 1.3;
  border: none;
  padding: 0 1.5em 0 0;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  background-color: transparent;
  background-position: right center;
  background-repeat: no-repeat;
  background-size: 0.8em 0.8em;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-radius: 0;
  z-index: 1;
  opacity: 0.4;
  box-shadow: none;
  transition: opacity 0.1s;
  overflow: hidden;
  resize: none; }
  .form input:hover, .form select:hover, .form textarea:hover {
    opacity: 1; }
  .form input:focus, .form select:focus, .form textarea:focus {
    opacity: 1;
    outline: 0; }

.form label {
  display: none; }

.form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between; }
  .form .headline, .form .body-copy {
    width: 100%; }
  .form.module {
    padding: 1.5vw;
    background-color: #D04B8E; }
  .form-field {
    position: relative;
    width: 100%;
    margin-top: 3em; }
  .form select {
    background: url("../img/form-select.svg") no-repeat right 6px;
    background-size: 0.8em auto; }
    .form select option {
      padding: 0;
      margin: 0; }
    .form select .option-hidden {
      display: none; }
    .form select::-ms-expand {
      display: none; }
  .form textarea {
    resize: none;
    overflow: hidden; }
  .form text {
    text-align: center; }
  .form-field-error {
    display: none; }
  .form-error {
    padding: 3px 0 0 2.2em;
    color: red;
    font-size: 0.8077rem;
    line-height: 1.2;
    position: relative;
    margin-bottom: 1em; }
    .form-error:before {
      content: '';
      position: absolute;
      width: 40px;
      height: 40px;
      left: 0;
      top: -4px; }
  .form .form-field-messages {
    padding: 3px 0 0 2.2em;
    color: white;
    font-size: 1rem;
    line-height: 1.2;
    position: relative;
    margin-bottom: 1em;
    text-align: center; }

.disabled {
  display: none; }

form button, [role="button"], form input[type=submit], .ajax, .cta {
  background: transparent;
  border-color: white;
  color: white;
  border-style: solid;
  border-width: 2px;
  border-radius: 0;
  font-family: "Gilroy Extra Bold";
  font-size: 0.8077rem;
  font-weight: 700;
  letter-spacing: 0em;
  line-height: 1;
  padding: 1.5em 6em;
  text-align: center;
  text-transform: uppercase;
  transition: border-color .1s, background-color .1s, color .1s;
  -webkit-appearance: none; }
  form button:hover, [role="button"]:hover, form input[type=submit]:hover, .ajax:hover, .cta:hover {
    background-color: rgba(255, 255, 255, 0.25); }
  form button[type=reset], [role="button"][type=reset], form input[type=submit][type=reset], .ajax[type=reset], .cta[type=reset] {
    border: none; }
    form button[type=reset]:hover, [role="button"][type=reset]:hover, form input[type=submit][type=reset]:hover, .ajax[type=reset]:hover, .cta[type=reset]:hover {
      background-color: transparent; }

.buttons {
  margin-top: 2rem;
  display: flex;
  justify-content: flex-start; }
  .buttons button, .buttons [type=submit] {
    margin-right: 2rem; }

form input[type=submit] {
  width: auto;
  margin-top: 2rem; }
  form input[type=submit]:hover {
    cursor: pointer; }

form {
  background: transparent; }
  form .form-field {
    color: white; }
  form .form-input {
    border-color: white; }
    form .form-input:focus {
      border-bottom-color: white; }
  form .form-submit {
    color: white; }
  form .form-text {
    color: white; }

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: white; }

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: white;
  opacity: 1; }

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: white;
  opacity: 1; }

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: white; }

.alert.red {
  margin-top: 2rem;
  color: red; }
  .alert.red p {
    line-height: 1.5rem; }

/* global */
html {
  color: #323232;
  height: 100%; }

body {
  height: 100%;
  margin: 0;
  position: relative;
  overflow-x: hidden;
  z-index: 0; }
  body.isOpenSiteNavigation {
    overflow-y: hidden; }
  body:after {
    content: "";
    position: fixed;
    /* stretch a fixed position to the whole screen */
    top: 0;
    height: 100vh;
    /* fix for mobile browser address bar appearing disappearing */
    left: 0;
    right: 0;
    z-index: -1;
    /* needed to keep in the background */
    background: var(--bg-img);
    background-size: cover;
    background-position-y: top;
    background-position-x: var(--bg-position); }

.hidden {
  display: none !important; }

.visually-hidden, .skip {
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  position: absolute;
  clip: rect(0 0 0 0);
  overflow: hidden; }

.hide-overflow {
  overflow: hidden; }

/* styling for various page templates */
.wrapper {
  margin-left: 5%;
  position: relative;
  background-color: transparent;
  z-index: 2;
  width: 90%; }

/* main element styles */
[role='main'] {
  height: 100%;
  position: relative;
  z-index: 2;
  background-color: white;
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden; }
  [role='main']:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -3rem;
    height: 3rem;
    width: 100%;
    border: none;
    margin: 0; }

/* base module styles */
.module {
  width: 100%;
  margin: 0 auto 10vw auto;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  background-color: transparent; }
  .module-content {
    width: 100%;
    position: relative;
    padding-top: calc(1.5vw * 1);
    padding-bottom: calc(1.5vw * 1);
    padding-left: 1.5vw;
    padding-right: 1.5vw;
    overflow: hidden; }
    .module-content.full-width {
      padding-left: 0;
      padding-right: 0; }
    .module-content a {
      width: auto;
      color: white;
      text-decoration: underline;
      transition: opacity 0.5s cubic-bezier(0.03, 0.46, 0.31, 0.97); }
      .module-content a:hover {
        opacity: 0.6; }
  .module:nth-child(3n+1):not(:first-child) .headline, .module:nth-child(3n+1):not(:first-child) .subhead, .module:nth-child(3n+1):not(:first-child) .body-copy, .module:nth-child(3n+1):not(:first-child) time {
    color: white; }
  .module:nth-child(3n+2) .headline, .module:nth-child(3n+2) .subhead, .module:nth-child(3n+2) .body-copy, .module:nth-child(3n+2) time {
    color: white; }
  .module:nth-child(3n+3) .headline, .module:nth-child(3n+3) .subhead, .module:nth-child(3n+3) .body-copy, .module:nth-child(3n+3) time {
    color: white; }
  .module .headline {
    position: relative; }
  .module [role="button"], .module .ajax, .module .cta {
    color: currentColor;
    background: transparent;
    border-style: solid;
    border: 0;
    border-radius: 0;
    font-family: "Gilroy Extra Bold", Helvetica, Arial, sans-serif;
    font-size: 0.8077rem;
    font-weight: 700;
    letter-spacing: 0.02em;
    line-height: 1;
    padding: 1.15em 3em;
    text-align: center;
    text-transform: uppercase;
    -webkit-appearance: none;
    position: relative;
    display: inline-block;
    border: 2px solid rgba(64, 64, 64, 0.2);
    transition: all 0.2s cubic-bezier(0.32, 0.01, 0, 1); }
    .module [role="button"]:hover, .module [role="button"]:focus, .module .ajax:hover, .module .ajax:focus, .module .cta:hover, .module .cta:focus {
      cursor: pointer;
      outline: none;
      border: 2px solid #404040; }

[target="social"] .mls {
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  position: absolute;
  clip: rect(0 0 0 0);
  overflow: hidden; }

:-webkit-full-screen-ancestor > body {
  height: initial; }

@media screen and (min-width: 992px) {
  body {
    background: var(--bg-img);
    background-attachment: fixed;
    background-size: cover;
    background-position-y: top;
    background-position-x: var(--bg-position); }
    body:after {
      content: none; }
  .wrapper {
    width: 45%; } }

@media screen and (min-width: 1200px) {
  .module [role="button"], .module .ajax, .module .cta {
    font-size: 1rem; } }

/* modules */
.bio .module-content {
  padding: calc(1.5vw * 2);
  padding-bottom: calc(6vw - 1rem); }
  .bio .module-content .bio-details {
    display: flex;
    flex-direction: column; }
    .bio .module-content .bio-details .headline {
      order: 2; }
    .bio .module-content .bio-details img {
      order: 1;
      width: 100%;
      margin: 0 0 1rem 0; }
  .bio .module-content .body-copy {
    color: white;
    margin-top: 1rem;
    font-size: 1.3077rem; }
    .bio .module-content .body-copy p {
      line-height: 1.3; }
      .bio .module-content .body-copy p a {
        color: currentColor; }

.bio:nth-child(3n+1):not(:first-child) .module-content {
  background-color: #9d6523; }

.bio:nth-child(3n+2) .module-content {
  background-color: #82231a; }

.bio:nth-child(3n+3) .module-content {
  background-color: #28746a; }

@media screen and (min-width: 768px) {
  .bio .module-content .body-copy strong {
    font-size: 1.3077rem;
    line-height: 1.4;
    letter-spacing: 0em;
    font-family: "Gilroy Light", Times, Georgia, serif;
    font-weight: 400;
    font-weight: bold; }
  .bio .module-content .body-copy p {
    font-size: 1.3077rem; }
  .bio .module-content .body-copy time {
    font-size: 1.3077rem; } }

@media screen and (min-width: 1200px) {
  .bio .module-content .body-copy strong {
    font-size: 1.6154rem;
    line-height: 1.4;
    letter-spacing: 0em;
    font-family: "Gilroy Light", Times, Georgia, serif;
    font-weight: 400;
    font-weight: bold; }
  .bio .module-content .body-copy p {
    font-size: 1.6154rem; }
  .bio .module-content .body-copy time {
    font-size: 1.6154rem; } }

.site-info {
  position: relative;
  width: 100%;
  height: 35vh;
  margin: 0;
  padding: 0; }
  .site-info.module:not(:first-child) {
    background-color: rgba(50, 50, 50, 0.5); }
  .site-info .module-content {
    height: 35vh;
    padding-top: calc(1.5vw * 0.5);
    padding-bottom: calc(1.5vw * 0.5);
    padding-left: 1.5vw;
    padding-right: 1.5vw;
    padding-top: 3.75vw; }
    .news .site-info .module-content {
      padding-top: calc(1.5vw * 0.5);
      padding-bottom: calc(1.5vw * 0.5);
      padding-left: 1.5vw;
      padding-right: 1.5vw;
      padding-top: 3.75vw; }
    .site-info .module-content .site-title {
      font-size: 1rem;
      line-height: 1;
      letter-spacing: 0em;
      font-family: "Gilroy Extra Bold", Helvetica, Arial, sans-serif;
      font-weight: 700;
      font-size: calc(1rem + 2vw);
      margin-bottom: 0.5rem; }
      .site-info .module-content .site-title a {
        text-decoration: none; }
        .site-info .module-content .site-title a:after {
          content: none; }
    .site-info .module-content .site-tagline {
      font-size: 0.8077rem;
      line-height: 1;
      letter-spacing: 0em;
      font-family: "Gilroy Extra Bold", Helvetica, Arial, sans-serif;
      font-weight: 700;
      font-size: calc(0.8077rem + 1vw);
      margin-bottom: 0.5rem; }
    .site-info .module-content .copyright {
      font-size: 0.6154rem; }
    .site-info .module-content .site-details {
      position: relative;
      width: 100%;
      height: auto;
      color: white;
      margin-top: auto; }
    .site-info .module-content .link-container:after {
      display: inline;
      content: '|';
      color: #8d8d8d;
      font-weight: 400;
      position: relative;
      margin-left: 0.5em;
      margin-right: 0.5em; }
    .site-info .module-content .link-container:last-of-type:after {
      display: none; }
    .site-info .module-content .link-container a {
      color: white;
      text-decoration: none; }
      .site-info .module-content .link-container a:after {
        content: none; }
      .site-info .module-content .link-container a:active {
        color: #8d8d8d; }
        .site-info .module-content .link-container a:active:before, .site-info .module-content .link-container a:active:after {
          display: none;
          animation: none; }
      .site-info .module-content .link-container a:hover {
        color: #5a5a5a; }
    .site-info .module-content .links {
      font-family: "Gilroy Extra Bold", Helvetica, Arial, sans-serif;
      line-height: 1.4;
      letter-spacing: -0.02em;
      font-weight: 700;
      text-decoration: none;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin-bottom: 0.5rem;
      font-size: calc(1rem + 1vw);
      list-style: none;
      width: 100%; }
      .site-info .module-content .links .note {
        letter-spacing: 0; }
      .site-info .module-content .links.secondary {
        font-size: calc(0.6154rem + 1vw); }
      .site-info .module-content .links.social {
        flex-direction: row;
        flex-wrap: wrap;
        font-size: calc(0.6154rem + 1vw); }
        .site-info .module-content .links.social .link-container {
          margin-right: 1em; }
          .site-info .module-content .links.social .link-container:after {
            display: none; }
          .site-info .module-content .links.social .link-container:last-of-type {
            margin-right: 0; }

.header {
  margin: 25vh auto 0 auto;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  height: 100vh; }
  .header .site-title {
    color: white;
    font-size: calc(16.5vw + 1rem);
    font-family: "Gilroy Extra Bold";
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.51); }
  .header .page-title {
    color: white;
    font-size: calc(8vw + 1rem);
    font-family: "Gilroy Extra Bold";
    text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.51); }

@media screen and (min-width: 992px) {
  .header .site-title {
    color: white;
    font-size: calc(8.5vw + 1rem);
    font-family: "Gilroy Extra Bold"; }
  .header .page-title {
    color: white;
    font-size: calc(5vw + 1rem);
    font-family: "Gilroy Extra Bold"; } }

.link-list {
  background-color: #ff006e;
  margin: 1.5vw auto;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: calc(6vw - 1rem); }
  .link-list header {
    color: white;
    font-size: calc(4.5vw + 1rem);
    font-family: "Gilroy Extra Bold";
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.51); }
  .link-list ol li {
    background-color: white;
    display: block;
    width: 100%;
    height: auto;
    margin: 48px auto;
    padding: 1.5vw;
    position: relative;
    font-size: 1.3077rem;
    line-height: 1;
    letter-spacing: 0em;
    font-family: "Gilroy Extra Bold", Helvetica, Arial, sans-serif;
    font-weight: 700; }
    .link-list ol li strong {
      color: #CC0058;
      display: block; }
    .link-list ol li a {
      color: #fb5607;
      display: block;
      margin-top: 1.5vw;
      font-weight: bold; }
      .link-list ol li a:first-of-type {
        margin-top: 0; }
      .link-list ol li a:hover {
        color: #3a86ff; }
      .link-list ol li a:active {
        color: #ffbe0b; }
      .link-list ol li a:visited {
        color: #8338ec; }
    .link-list ol li strong + a:first-of-type {
      margin-top: 1.5vw; }

.news .module-content {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: calc(6vw - 1rem); }
  .news .module-content header {
    background-color: red;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding: 1.5vw;
    margin-bottom: 3vw;
    width: 100%; }
    .news .module-content header a {
      font-size: 1rem;
      line-height: 1;
      letter-spacing: 0em;
      font-family: "Gilroy Extra Bold", Helvetica, Arial, sans-serif;
      font-weight: 700; }
  .news .module-content ol li {
    display: block;
    width: 100%;
    height: auto;
    margin: 0 1.5vw 3vw 0;
    position: relative; }
    .news .module-content ol li .news-content {
      position: relative;
      padding: 1.5vw;
      width: 100%;
      height: auto;
      background-color: blue;
      border-width: 1.5vw; }
      .news .module-content ol li .news-content a {
        font-style: italic;
        font-weight: bold; }
    .news .module-content ol li strong {
      font-size: 2.1154rem;
      line-height: 1.4;
      letter-spacing: 0em;
      font-family: "Gilroy Light", Times, Georgia, serif;
      font-weight: 400;
      font-weight: bold; }
    .news .module-content ol li p {
      font-size: 1.3077rem;
      line-height: 1.5;
      letter-spacing: 0em;
      font-family: "Gilroy Light", Times, Georgia, serif;
      font-weight: 400;
      margin-bottom: 1em; }
    .news .module-content ol li time {
      display: block;
      font-size: 1rem;
      line-height: 1.5;
      letter-spacing: 0em;
      font-family: "Gilroy Light", Times, Georgia, serif;
      font-weight: 400;
      margin-bottom: 1em;
      font-style: italic; }

.news:nth-child(3n+1):not(:first-child) .module-content header {
  background-color: #9d6523; }

.news:nth-child(3n+1):not(:first-child) .module-content ol li .news-content {
  background-color: white;
  border: 1.5vw solid #D38C3A;
  color: #9d6523; }
  .news:nth-child(3n+1):not(:first-child) .module-content ol li .news-content a, .news:nth-child(3n+1):not(:first-child) .module-content ol li .news-content time {
    color: #9d6523; }

.news:nth-child(3n+2) .module-content header {
  background-color: #812c91; }

.news:nth-child(3n+2) .module-content ol li .news-content {
  background-color: white;
  border: 1.5vw solid #B144C6;
  color: #812c91; }
  .news:nth-child(3n+2) .module-content ol li .news-content a, .news:nth-child(3n+2) .module-content ol li .news-content time {
    color: #812c91; }

.news:nth-child(3n+3) .module-content header {
  background-color: #28746a; }

.news:nth-child(3n+3) .module-content ol li .news-content {
  background-color: white;
  border: 1.5vw solid #6ACBBE;
  color: #28746a; }
  .news:nth-child(3n+3) .module-content ol li .news-content a, .news:nth-child(3n+3) .module-content ol li .news-content time {
    color: #28746a; }

@media screen and (min-width: 768px) {
  .news .module-content ol {
    width: 100%; }
    .news .module-content ol li {
      min-height: calc(39vw);
      padding-top: 0; }
      .news .module-content ol li strong {
        font-size: 1.3077rem;
        line-height: 1.4;
        letter-spacing: 0em;
        font-family: "Gilroy Light", Times, Georgia, serif;
        font-weight: 400;
        font-weight: bold; }
      .news .module-content ol li p {
        font-size: 1.3077rem; }
      .news .module-content ol li time {
        font-size: 1.3077rem; } }

@media screen and (min-width: 1200px) {
  .news .module-content ol li {
    min-height: calc(25vw);
    padding-top: 0; }
    .news .module-content ol li p {
      font-size: 1.3077rem; }
    .news .module-content ol li time {
      font-size: 1.3077rem; } }

.nav-trigger {
  position: fixed;
  top: 3vw;
  right: 3vw;
  z-index: 10000;
  width: 100%;
  padding: 0;
  margin: 0; }

.navTrigger {
  background-color: #3c3c3c;
  padding: 0.5vw;
  margin: 0;
  display: inline;
  cursor: pointer;
  transition-property: opacity, letter-spacing;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  color: white;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.8077rem;
  line-height: 1;
  letter-spacing: 0em;
  font-family: "Gilroy Extra Bold", Helvetica, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 0.02em;
  text-decoration: none;
  text-transform: uppercase;
  transition: letter-spacing 0.5s cubic-bezier(0.03, 0.46, 0.31, 0.97); }
  .navTrigger:focus {
    outline: none; }
  .navTrigger:hover {
    letter-spacing: 0.075em; }
  .navTrigger-instructions {
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    position: absolute;
    clip: rect(0 0 0 0);
    overflow: hidden; }
  .navTrigger-label {
    padding: 0; }
  .navTrigger.primary {
    color: #D04B8E; }
  .navTrigger.secondary {
    color: #C23427; }
  .navTrigger.tertiary {
    color: #D38C3A; }
  .navTrigger.quaternary {
    color: #6ACBBE; }
  .navTrigger.isOpen {
    background-color: transparent;
    opacity: 1; }
    .navTrigger.isOpen.primary {
      color: white; }
    .navTrigger.isOpen.secondary {
      color: white; }
    .navTrigger.isOpen.tertiary {
      color: white; }
    .navTrigger.isOpen.quaternary {
      color: white; }
    .navTrigger.isOpen .navTrigger-instructions {
      width: auto;
      height: auto;
      clip: auto;
      margin: 0;
      position: relative; }

.project .module-content {
  overflow: hidden;
  padding: 0; }
  .project .module-content .project-details {
    color: #3c3c3c;
    background-color: white;
    border: 1.5vw solid #8d8d8d;
    padding: calc(1.5vw * 2);
    width: 100%; }
    .project .module-content .project-details img {
      width: 100%;
      margin: 0;
      border: calc(calc(1.5vw/2) / 6) solid #323232; }
    .project .module-content .project-details .headline {
      color: #3c3c3c; }
    .project .module-content .project-details .project-links {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      max-width: 100%;
      margin-top: 2rem; }
      .project .module-content .project-details .project-links .project-link {
        margin-right: 1rem;
        margin-bottom: 1rem; }
        .project .module-content .project-details .project-links .project-link:after {
          display: none; }
        .project .module-content .project-details .project-links .project-link:last-of-type {
          margin-right: 0; }
        .project .module-content .project-details .project-links .project-link a {
          color: #3c3c3c;
          font-size: calc(3.4231rem + 1vw);
          text-decoration: none; }
        .project .module-content .project-details .project-links .project-link .mls {
          width: 1px;
          height: 1px;
          margin: -1px;
          padding: 0;
          border: 0;
          position: absolute;
          clip: rect(0 0 0 0);
          overflow: hidden; }
      @media screen and (orientation: portrait) {
        .project .module-content .project-details .project-links {
          justify-content: flex-start; } }
  .project .module-content .headline {
    font-size: 1.6154rem;
    margin-bottom: 2rem; }
  .project .module-content .body-copy {
    background: #3c3c3c;
    padding: calc(1.5vw * 2);
    width: 100%;
    font-size: 1.3077rem;
    line-height: 1.6154rem;
    overflow: hidden; }
    .project .module-content .body-copy p {
      line-height: 1.3;
      margin-bottom: 1rem; }
      .project .module-content .body-copy p:last-of-type {
        margin-bottom: 0; }
    .project .module-content .body-copy img {
      width: 20vw;
      float: left;
      margin: 1rem 2rem; }

@media screen and (min-width: 768px) {
  .project .module-content .project-details {
    padding: 1.5vw; }
  .project .module-content .headline {
    font-size: 1.6154rem; }
  .project .module-content .body-copy {
    padding: 1.5vw; } }

@media screen and (min-width: 992px) {
  .project .module-content .headline {
    font-size: 2.6154rem; }
  .project .module-content .project-details ul li {
    font-size: 1.3077rem; } }

.site-navigation {
  position: fixed;
  z-index: 911;
  width: 100%;
  left: 0;
  overflow: hidden;
  color: #323232;
  top: 0; }
  .site-navigation .site-nav {
    position: relative;
    width: 100%;
    min-height: 100%;
    height: auto;
    padding: 0;
    margin: 0; }
    .site-navigation .site-nav .home {
      position: absolute;
      top: 3vw;
      left: 3vw;
      color: white;
      z-index: 913;
      font-size: 0.8077rem;
      line-height: 1;
      letter-spacing: 0em;
      font-family: "Gilroy Extra Bold", Helvetica, Arial, sans-serif;
      font-weight: 700;
      letter-spacing: 0.02em;
      text-decoration: none;
      text-transform: uppercase;
      transition: letter-spacing 0.5s cubic-bezier(0.03, 0.46, 0.31, 0.97), opacity 0.5s cubic-bezier(0.03, 0.46, 0.31, 0.97);
      opacity: 0; }
      .site-navigation .site-nav .home:hover {
        letter-spacing: 0.075em; }
      .site-navigation .site-nav .home.scrolled {
        opacity: 1;
        display: block;
        height: auto;
        width: auto; }
      .site-navigation .site-nav .home.primary {
        color: #D04B8E; }
      .site-navigation .site-nav .home.secondary {
        color: #C23427; }
      .site-navigation .site-nav .home.tertiary {
        color: #D38C3A; }
      .site-navigation .site-nav .home.quaternary {
        color: #6ACBBE; }
      .site-navigation .site-nav .home.isOpen {
        opacity: 1; }
        .site-navigation .site-nav .home.isOpen.primary {
          color: white; }
        .site-navigation .site-nav .home.isOpen.secondary {
          color: white; }
        .site-navigation .site-nav .home.isOpen.tertiary {
          color: white; }
        .site-navigation .site-nav .home.isOpen.quaternary {
          color: white; }
    .site-navigation .site-nav .inner-nav {
      position: relative;
      width: 100%;
      min-height: 100%;
      height: 9vh;
      display: flex;
      flex-flow: column wrap;
      align-items: flex-start;
      justify-content: flex-start;
      opacity: 0;
      background-color: #a42b68;
      transition: opacity 0.5s cubic-bezier(0.03, 0.46, 0.31, 0.97), height 0s 2s linear;
      z-index: 912; }
  .site-navigation.closing .inner-nav {
    background-color: white; }
  .site-navigation.closing .inner-nav,
  .site-navigation.isOpen.closing .inner-nav {
    transition: all 0.5s 0s cubic-bezier(0.32, 0.01, 0, 1);
    transform: scale(0.9);
    opacity: 0; }
  .site-navigation.isOpen .inner-nav {
    opacity: 1;
    transition: opacity 0.2s 0s cubic-bezier(0.03, 0.46, 0.31, 0.97);
    padding: 20vw 4vw;
    height: 100vh;
    transition: height 0s 0s linear;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden; }
  .site-navigation.isOpen .links .link-container {
    transition: all 0.3s cubic-bezier(0.03, 0.46, 0.31, 0.97); }
    .site-navigation.isOpen .links .link-container:nth-of-type(11n + 0) {
      transition-delay: 0s; }
    .site-navigation.isOpen .links .link-container:nth-of-type(11n + 1) {
      transition-delay: 0.05s; }
    .site-navigation.isOpen .links .link-container:nth-of-type(11n + 2) {
      transition-delay: 0.1s; }
    .site-navigation.isOpen .links .link-container:nth-of-type(11n + 3) {
      transition-delay: 0.15s; }
    .site-navigation.isOpen .links .link-container:nth-of-type(11n + 4) {
      transition-delay: 0.2s; }
    .site-navigation.isOpen .links .link-container:nth-of-type(11n + 5) {
      transition-delay: 0.25s; }
    .site-navigation.isOpen .links .link-container:nth-of-type(11n + 6) {
      transition-delay: 0.3s; }
    .site-navigation.isOpen .links .link-container:nth-of-type(11n + 7) {
      transition-delay: 0.35s; }
    .site-navigation.isOpen .links .link-container:nth-of-type(11n + 8) {
      transition-delay: 0.4s; }
    .site-navigation.isOpen .links .link-container:nth-of-type(11n + 9) {
      transition-delay: 0.45s; }
  .site-navigation.isOpen .link-container {
    opacity: 1;
    transform: translateY(0%); }
  .site-navigation .link-container {
    opacity: 0;
    transform: translateY(-50%);
    transition: all 0.35s cubic-bezier(0.03, 0.46, 0.31, 0.97); }
    .site-navigation .link-container:after {
      display: inline;
      content: '|';
      color: #f0c4da;
      font-weight: 400;
      position: relative;
      margin-left: 0.5em;
      margin-right: 0.5em; }
    .site-navigation .link-container:last-of-type:after {
      display: none; }
    .site-navigation .link-container a {
      color: white; }
      .site-navigation .link-container a:active {
        color: #8d8d8d; }
        .site-navigation .link-container a:active:before, .site-navigation .link-container a:active:after {
          display: none;
          animation: none; }
      .site-navigation .link-container a:hover {
        color: #f0c4da; }
  .site-navigation .links {
    font-family: "Gilroy Extra Bold", Helvetica, Arial, sans-serif;
    letter-spacing: -0.02em;
    font-weight: 700;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 0.25em;
    list-style: none;
    width: 100%;
    line-height: 1;
    font-size: calc(1.6154rem + 1vw); }
    @media screen and (orientation: portrait) {
      .site-navigation .links {
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start; } }
    .site-navigation .links .note {
      letter-spacing: 0; }
    @media screen and (orientation: portrait) {
      .site-navigation .links .link-container:after {
        content: none; } }
    .site-navigation .links.secondary {
      font-size: calc(1rem + 1vw); }
    .site-navigation .links.social {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      max-width: 100%;
      margin-top: 2rem;
      font-size: calc(1.6154rem + 1vw); }
      .site-navigation .links.social .link-container {
        margin-right: 1rem;
        margin-bottom: 1rem; }
        .site-navigation .links.social .link-container:after {
          display: none; }
        .site-navigation .links.social .link-container:last-of-type {
          margin-right: 0; }
      @media screen and (orientation: portrait) {
        .site-navigation .links.social {
          justify-content: flex-start; } }

@media screen and (min-width: 768px) {
  .site-navigation .site-nav .inner-nav {
    align-items: center; }
  .site-navigation .links {
    line-height: 1;
    font-size: calc(2.6154rem + 1vw); }
    .site-navigation .links.secondary {
      font-size: calc(1.3077rem + 1vw); }
    .site-navigation .links.social {
      width: 100%;
      flex-wrap: nowrap; } }

@media screen and (min-width: 992px) {
  .site-navigation.isOpen .site-nav .inner-nav {
    flex-direction: row;
    padding-top: 10vw;
    overlfow: hidden; }
  .site-navigation .links {
    font-size: calc(3.4231rem + 1vw); } }

@media screen and (min-width: 1200px) {
  .site-navigation .links {
    font-size: 4.2308rem; }
    .site-navigation .links.secondary {
      font-size: calc(1.6154rem + 1vw); } }

.statement .module-content {
  background-color: red;
  padding: calc(1.5vw * 2); }
  .statement .module-content .headline {
    font-size: 2.1154rem; }
  .statement .module-content .body-copy {
    font-size: 1.3077rem;
    line-height: 1.4;
    letter-spacing: 0em;
    font-family: "Gilroy Light", Times, Georgia, serif;
    font-weight: 400;
    font-weight: bold;
    line-height: 1.2;
    overflow: hidden; }
    .statement .module-content .body-copy img {
      width: 20vw;
      float: left;
      margin: 1rem 2rem; }

.statement:nth-child(3n+1):not(:first-child) .module-content {
  background-color: #D38C3A; }

.statement:nth-child(3n+2) .module-content {
  background-color: #C23427; }

.statement:nth-child(3n+3) .module-content {
  background-color: #6ACBBE; }

@media screen and (min-width: 992px) {
  .statement .module-content .headline {
    font-size: 3.4231rem; }
  .statement .module-content .body-copy {
    font-size: 2.1154rem; } }
