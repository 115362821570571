//*------------------------------------*\
//    $TYPOGRAPHIC MIXINS
//*------------------------------------*/
@mixin font-creation($name, $filename, $font-weight:400) {
    @font-face {
      font-family: $name;
      font-weight: $font-weight;
      font-style: normal;
      unicode-range: U+000-5FF; /* Download only latin glyphs */
      src: local($name),
           url('#{$thempath}/fonts/#{$filename}.woff2') format('woff2'), 
           url('#{$thempath}/fonts/#{$filename}.woff') format('woff');
    }
}

// ======================================
// Font Declarations
// ======================================

@mixin font-declarations($font-family, $line-height, $letter-spacing, $font-weight:400, $font-size:$scale07, $font-style: null) {
    font-size: $font-size;
    line-height: $line-height;
    letter-spacing: $letter-spacing;
    font-style: $font-style;
    @if $font-family == $serif and $font-weight == 700 and $font-style == italic {
        font-family: $copy-font-name, Times, Georgia, serif;
        font-weight: $font-weight-regular;
    }
    @if $font-family == $serif and $font-weight == 700 and $font-style == null {
        font-family: $copy-font-name, Times, Georgia, serif;
        font-weight: $font-weight-regular;
    }
    @if $font-family == $serif and $font-weight == 400 and $font-style == italic {
        font-family: $copy-font-name, Times, Georgia, serif;
        font-weight: $font-weight;
    }
    @if $font-family == $serif and $font-weight == 400 and $font-style == null {
        font-family: $copy-font-name, Times, Georgia, serif;
        font-weight: $font-weight;
    }
    @if $font-family == $display-font-name {
        font-family: $font-family, Helvetica, Arial, sans-serif;
        font-weight: $font-weight;
    }
}


// ======================================
// Typographic Mixins
// ======================================

// Display Headline
@mixin display-headline($scale) {
	@include font-declarations($display, $title-serif, $spacing-serif, $font-weight-bold, $scale);
}

// Body Copy Headline
@mixin body-copy-headline($scale) {
	@include font-declarations($copy-font-name, $title-sans, $spacing-sans, $font-weight-bold, $scale);
}

// Body Copy Light
@mixin body-copy-light($scale) {
	@include font-declarations($copy-font-name, $prose-sans, $spacing-sans, $font-weight-regular, $scale);
    margin-bottom: 1em;
}

// Body Copy Bold
@mixin body-copy-bold($scale) {
	@include font-declarations($copy-font-name, $prose-sans, $spacing-sans, $font-weight-bold, $scale);
    margin-bottom: 1em;
}

@mixin profile-headline($scale) {
    @include font-declarations($display, $solid-serif, $spacing-serif, $font-weight-bold, $scale);
}

// Headline italic
@mixin display-headline-italic($scale) {
	@include font-declarations($display, $title-serif, $spacing-serif, $font-weight-bold, $scale, italic);
}

//global link
@mixin link() {
    display: inline;
    line-height: $title-serif;
    text-decoration: none;
    position: relative;
    cursor: pointer;
    color: $primary;
    background: linear-gradient(to bottom, currentColor 25%, rgba(0,0,0,0) 25%);
    background-repeat: no-repeat;
    background-position: right 1.1em;
    background-size: 0% .25em;
    transition: background-size 0.3s;
    text-decoration: underline;

    &:hover {
        // adding background-image style again endsures the background color
        // takes on the hover color, if different from off state
        background-image: linear-gradient(to bottom, currentColor 25%, rgba(0,0,0,0) 25%);
        background-size: 100% 0.25em;
        background-position: left 1.1em;
    }

    &.muted {
        color: $grayDarkest;
    }

    &-primary {
        color: $primary;
    }
    &-secondary {
        text-decoration: none;
        position: relative;
        display: inline-block;
        cursor: pointer;
    }
    &-arrow {
        margin-right: 1.5em;
        transition: 0s 0s;
        &:after {
            font-family: "icons";
            content: '\EA11';
            position: absolute;
            margin-left: 0.8em;
            font-size: 0.4em;
            vertical-align: middle;
            transition: margin-left 0.2s 0s $bezier;
        }

        &:hover:after {
            margin-left: 1.4em;
        }

    }
    &:focus {
        outline: none;
    }
}


