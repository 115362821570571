//*------------------------------------*\
//    $MEDIUM BREAKPOINT
//*------------------------------------*/

@media screen and (min-width: '#{$medium-breakpoint}px') {
	.project {
		.module-content {		
			.project-details {
				padding: $default-module-side-padding;
			}
	
			.headline {
				font-size: $scale06;
			}
		
			.body-copy {
				padding: $default-module-side-padding;
			}
		}	
	}
}