//*------------------------------------*\
//    $SMALL BREAKPOINT
//*------------------------------------*/

.site-info {
	position: relative;
	width: 100%;
	height: 35vh;
	margin: 0;
	padding: 0;
	
	
	&.module:not(:first-child) {
		background-color: rgba(50,50,50,0.5);
	}
	
	.module-content {
		height: 35vh;
		@include module-spacing(0.5, 0.5);
		padding-top: 3.75vw;

		.news & {
			@include module-spacing(0.5, 0.5);
			padding-top: 3.75vw;
		}
		
		.site-title {
			@include display-headline($scale04);
			font-size: calc(#{$scale04} + 2vw);
			margin-bottom: 0.5rem;
			
			a {
				text-decoration: none;

				&:after {
					content: none;
				}
			}
		}
		
		.site-tagline {
			@include display-headline($scale03);
			font-size: calc(#{$scale03} + 1vw);
			margin-bottom: 0.5rem;
		}
		
		.copyright {
			font-size: $scale02;
		}
		
		.site-details {
			position: relative;
			width: 100%;
			height: auto;
			color: $white;
			margin-top: auto;
		}

		.link-container {

			&:after {
				display: inline;
				content: '|';
				color: $gray;
				font-weight: $font-weight-regular;
				position: relative;
				margin-left: 0.5em;
				margin-right: 0.5em;
			}

			&:last-of-type:after {
				display: none;
			}

			a {
				color: $white;
				text-decoration: none;
				
				&:after {
					content: none;
				}
				
				&:active {
					color: $gray;

					&:before,
					&:after {
						display: none;
						animation: none;
					}
				}
			
				&:hover {
					color: darken($gray, 20%);
				}
			}
		}

		.links {
			font-family: $sans;
			line-height: 1.4;
			letter-spacing: -0.02em;
			font-weight: $font-weight-bold;
			text-decoration: none;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: flex-start;
			margin-bottom: 0.5rem;
			font-size: calc(#{$scale04} + 1vw);
			list-style: none;
			width: 100%;

			.note {
				letter-spacing: 0;
			}
		
			&.secondary {
			
				font-size: calc(#{$scale02} + 1vw);
		
			}
		
			&.social {
				flex-direction: row;
				flex-wrap: wrap;
				font-size: calc(#{$scale02} + 1vw);
			
				.link-container {
					margin-right: 1em;
				
					&:after {
						display: none;
					}
				
					&:last-of-type {
						margin-right: 0;
					}
				}
			
			
			}

		}

	}
}