//*------------------------------------*\
//    $LARGE BREAKPOINT
//*------------------------------------*/

@media screen and (min-width: '#{$large-breakpoint}px') {
	body {
		background: var(--bg-img);
		background-attachment: fixed;
        background-size: cover;
        background-position-y: top;
        background-position-x:  var(--bg-position);

	    &:after {
	        content:none;
	    }
	}

	.wrapper {
		width: 45%;
	}
   
}
