//*------------------------------------*\
//    $SMALL BREAKPOINT
//*------------------------------------*/

.site-navigation {
    position: fixed;
    z-index: $z-index-900 + 11;
    width: 100%;
    left: 0;
    overflow: hidden;
    color: $grayDarkest;
    top: 0;
    
    .site-nav {
        position: relative;
        width: 100%;
        min-height: 100%;
        height: auto;
        padding: 0;
        margin: 0;
        
        .home {
        	position: absolute;
        	top: 3vw;
        	left: 3vw;
        	color: $white;
        	z-index: $z-index-900 + 13;
        	@include display-headline($scale03);
			letter-spacing: 0.02em;
			text-decoration: none;
			text-transform: uppercase;
			transition: letter-spacing 0.5s $bezier-nav, opacity 0.5s $bezier-nav;
			opacity: 0;
			
			&:hover {
				letter-spacing: 0.075em;
			}
			
			&.scrolled {
				opacity: 1;
				display: block;
				height: auto;
				width: auto;
			}
			
			// set to text color to be visible on module backgrounds
			&.primary {
				color: $primary;
			}
	
			&.secondary {
				color: $secondary;
			}
	
			&.tertiary {
				color: $tertiary;
			}
			
			&.quaternary {
				color: $quaternary;
			}

			// Toggle `isOpen` class with JS to animate
			&.isOpen {
				opacity: 1;
			
				&.primary {
					color: $white;
				}
	
				&.secondary {
					color: $white;
				}
	
				&.tertiary {
					color: $white;
				}
				
				&.quaternary {
					color: $white;
				}
			}
        }
        
        
        
        .inner-nav {
        	position: relative;
        	width: 100%;
        	min-height: 100%;
        	height: 9vh;
			display: flex;
			flex-flow: column wrap;
			align-items: flex-start;
			justify-content: flex-start;
			opacity: 0;
			background-color: $primaryDarker;
			transition: opacity 0.5s $bezier-nav, height 0s 2s linear;
			z-index: $z-index-900 + 12;
        }
    
    }

    &.closing {
    	.inner-nav {
        	background-color: $white;
        }
    }

    &.closing .inner-nav,
    &.isOpen.closing .inner-nav {
        transition: all 0.5s 0s $bezier;
        transform: scale(0.9);
        opacity: 0;
    }

    &.isOpen {
        
        .inner-nav {
    		opacity: 1;
            transition: opacity 0.2s 0s $bezier-nav;
        	padding: 20vw 4vw;
            height: 100vh;
			transition: height 0s 0s linear;
			overflow-y: scroll;
			-webkit-overflow-scrolling: touch;
			overflow-x: hidden;
    	}

        .links {
            .link-container {
                transition: all 0.3s $bezier-nav;
                @for $i from 0 to 10 {
                    &:nth-of-type(11n + #{$i}) {
                        transition-delay: 0s + (0.05 * $i);
                    }
                }
            }
        }

        .link-container {
            opacity: 1;
            transform: translateY(0%);
        }
    }

    .link-container {
        opacity: 0;
        transform: translateY(-50%);
        transition: all 0.35s $bezier-nav;

        &:after {
            display: inline;
            content: '|';
            color: $primaryLighter;
            font-weight: $font-weight-regular;
            position: relative;
            margin-left: 0.5em;
            margin-right: 0.5em;
        }

        &:last-of-type:after {
            display: none;
        }

        a {
        	color: $white;
            &:active {
                color: $gray;

                &:before,
                &:after {
                    display: none;
                    animation: none;
                }
            }
            
            &:hover {
                color: $primaryLighter;
            }
        }
    }

    .links {
        font-family: $sans;
        letter-spacing: -0.02em;
        font-weight: $font-weight-bold;
        text-decoration: none;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 0.25em;
        list-style: none;
        width: 100%;
		line-height: 1;
		font-size: calc(#{$scale06} + 1vw);

        @media screen and (orientation: portrait) {
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: flex-start;
        }

        .note {
            letter-spacing: 0;
        }

        .link-container:after {
            @media screen and (orientation: portrait) {
                content: none;
            }
        }
        
        &.secondary {
        	
        	font-size: calc(#{$scale04} + 1vw);
        
        }
        
        &.social {
        	flex-direction: row;
        	flex-wrap: wrap;
        	justify-content: center;
        	max-width: 100%;
        	margin-top: 2rem;
        	font-size: calc(#{$scale06} + 1vw);
        	
        	.link-container {
        		margin-right: 1rem;
        		margin-bottom: 1rem;
        		
				&:after {
					display: none;
				}
				
				&:last-of-type {
					margin-right: 0;
				}
			}
			
			@media screen and (orientation: portrait) {
				justify-content: flex-start;
			}
        	
        	
        }

    }
    
    
    
    
}
