//*------------------------------------*\
//    $SMALL BREAKPOINT
//*------------------------------------*/

html {
    color: $black;
    height: 100%;
}

body {
	height: 100%;
	margin: 0;
	position: relative;
	overflow-x: hidden;
	z-index: 0;

	&.isOpenSiteNavigation {
		overflow-y: hidden;
	}

    &:after {
        content:"";
        position:fixed; /* stretch a fixed position to the whole screen */
        top:0;
        height:100vh; /* fix for mobile browser address bar appearing disappearing */
        left:0;
        right:0;
        z-index:-1; /* needed to keep in the background */
        background: var(--bg-img);
        background-size: cover;
        background-position-y: top;
        background-position-x:  var(--bg-position);
    }
}

.hidden {
    display: none !important;
}

.visually-hidden, .skip {
    @include isVisuallyHidden();
}

.hide-overflow {
    overflow: hidden;
}

/* styling for various page templates */

.wrapper {
    margin-left: 5%;
	position: relative;
    background-color: transparent;
    z-index: 2;
    width: 90%;
}

/* main element styles */

[role='main'] {
	height: 100%;
	position: relative;
	z-index: 2;
	background-color: $white;
	-webkit-transform: translate3d(0, 0, 0);
	-webkit-backface-visibility: hidden;

	&:after {
		content: '';
    	position: absolute;
    	left: 0;
    	bottom: -3rem;
    	height: 3rem;
		width: 100%;
		border: none;
		margin: 0;
	}
}

/* base module styles */

.module {
    width: 100%;
    margin: 0 auto 10vw auto;
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
    background-color: transparent;

    &-content {
        width: 100%;
        position: relative;
        @include module-spacing;
        overflow: hidden;

        &.full-width {
            padding: {
                left: 0;
                right: 0;
            }
        }
        
        a {
			width: auto;
			color: $white;
			text-decoration: underline;
			transition: opacity 0.5s $bezier-nav;
			
			&:hover {
				opacity: 0.6;
			}
		}
    }
    
    &:nth-child(3n+1):not(:first-child) {	
    	.headline, .subhead, .body-copy, time {
    		color: $white;
    	}
    }
    
    &:nth-child(3n+2) {
        .headline, .subhead, .body-copy, time {
    		color: $white;
    	}
    }
    
    &:nth-child(3n+3) {
    	.headline, .subhead, .body-copy, time {
    		color: $white;
    	}
    }

    .headline {
        position: relative;
    }

    [role="button"], .ajax, .cta {
        color: currentColor;
		background: transparent;
		border-style: solid;
        border: 0;
        border-radius: 0;
		font-family: $sans;
		font-size: $scale03;
		font-weight: $font-weight-bold;
		letter-spacing: $spacing-sans-large;
		line-height: $solid-sans;
		padding: 1.15em 3em;
		text-align: center;
		text-transform: uppercase;
        -webkit-appearance: none;
        position: relative;
        display: inline-block;
        border: 2px solid rgba(64, 64, 64, $buttonOpacity);
        transition: all 0.2s $bezier;

		&:hover, &:focus {
		    cursor: pointer;
            outline: none;
            border: 2px solid rgba(64, 64, 64, 1);
		}
	}

}

[target="social"] {
	.mls {
		@include isVisuallyHidden();
	}
}



// height: 100% is the default value, which causes the page to scroll to top
// when exiting fullscreen. Resetting it to browser default while in fullscreen
// to prevent page from recalculating height and causing it to scroll to top.
:-webkit-full-screen-ancestor>body {
    height: initial;
}
