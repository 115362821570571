//*------------------------------------*\
//    $GLOBAL ANIMATIONS
//*------------------------------------*/



@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}


@keyframes submit_span_1 {
    to {
        opacity: 0;
        transform: translate(-50%, 250%);
    }
}


@keyframes submit_2 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
}

@keyframes submit_3 {
    0% {
        border-top: 2px $primary solid;
        border-left: 0px $primary solid;
        border-right: 0px $primary solid;
        border-bottom: 0px $primary solid;
    }
    20% {
        border-top: 2px $primary solid;
        border-left: 2px $primary solid;
        border-right: 2px $primary solid;
        border-bottom: 2px $primary solid;
        box-shadow: inset 0 0 0 0 $primary;
    }
    100% {
        border-top: 1.75rem $primary solid;
        border-left: 1.75rem $primary solid;
        border-right: 1.75rem $primary solid;
        border-bottom: 1.75rem $primary solid;
    }
}

@keyframes submit_4 {
    0% {
        border-bottom: 4px $white solid;
        width: 0em;
        border-left: 4px $white solid;
        height: 0em;
    }
    40% {
        border-bottom: 4px $white solid;
        width: 0em;
        border-left: 4px $white solid;
        height: 1em;
    }
    60% {
        border-bottom: 4px $white solid;
        width: 0em;
        border-left: 4px $white solid;
        height: 0.75em;
    }
    100% {
        border-bottom: 4px $white solid;
        width: 1.5em;
        border-left: 4px $white solid;
        height: 0.75em;
    }
}


@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}


/* page in/out animation */





@keyframes animateIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes animateOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}


/* base module styles */

.module {
    &[data-position] {
        .module-content {
            transition-property: all;
            transition-timing-function: $bezier-nav;
            transform: translateY(0);
            opacity: 1;
            transition-duration: 0.75s;
        }
    }
    &[data-position="below-viewport"] {
        .module-content {
            transform: translateY(100%);
            transition-duration: 0s;
            opacity: 0;
        }
    }
}

@keyframes fields_visible {
    0% {
        transform: translateY(100px);
        opacity: 0;
    }
    40% {
        transform: translateY(100px);
        opacity: 0;
    }
    80% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes fields_hide {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    99% {
        opacity: 0;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(100px);
    }
}

@keyframes linkunderlinein {
    0% {
        background-size: 0% 0.25em;
        background-position: left 1.1em;
    }
    100% {
         background-size: 100% 0.25em;
         background-position: left 1.1em;
    }
}

@keyframes linkunderlineout {
    0% {
        background-size: 100% 0.25em;
        background-position: right 1.1em;
    }
    100% {
         background-size: 0% 0.25em;
         background-position: right 1.1em;
    }
}

@keyframes navItemHover {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 1;
    }
    80% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes heroFirstChild {
    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}
