//*------------------------------------*\ 
//    Z-Index VARIABLES 
//*------------------------------------*/ 
 
$z-index-000: 0; 
$z-index-100: 100; 
$z-index-200: 200; 
$z-index-300: 300; 
$z-index-400: 400; 
$z-index-500: 500; 
$z-index-600: 600; 
$z-index-700: 700; 
$z-index-800: 800; 
$z-index-900: 900;