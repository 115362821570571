/*
 * Using full hand notation to use animation-delay elsewhere
 */
 
@mixin form-styles($color1, $color2) {
    .form {
        &-field {
            color: $color2;
        }

        &-input {
            border-color: $color1;

            &:focus {
                border-bottom-color: $color2;
            }
        }

        &-submit {
            color: $color2;
        }
        &-text {
            color: $color2;
        }
    }
}
 
@mixin fields-visible {
    animation-name: fields_visible;
    animation-duration: 0.6s;
    animation-timing-function: ease;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: forwards;
    animation-play-state: running;
}

@mixin fields-hide {
    animation-name: fields_hide;
    animation-duration: 0.2s;
    animation-delay: 0s;
    animation-timing-function: ease-in;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: forwards;
    animation-play-state: running;
}

@mixin submit-button {
    box-sizing: border-box;
    background-color: transparent;
    color: $white;
    line-height: 1;
    outline: none;
    overflow: hidden;
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    padding-left: 6em;
    padding-right: 6em;
    position: relative;
    transition: all 0.2s $bezier;
    text-transform: uppercase;
    height: 3.5rem;
    width: 10rem;
    border: 2px $white solid;
    margin: 0 auto;
    display: block;

    &:before {
        content: '';
        display: block;
        background-color: transparent;
        border: none;
        border-bottom: 0px $white solid;
        border-left: 0px $white solid;
        width: 0em;
        height: 0em;
        position: absolute;
        top: 37%;
        left: 31%;
        z-index: 2000;
        opacity: 1;
    }

    &:hover {
        border: 2px currentColor solid;
        transition: none;
    }

    span {
        position: absolute;
        top: 50%;
        left: 50%;
        opacity: 1;
        transform: translate(-50%, -50%);
        pointer-events: none;
        display: block;
    }

    .label {
        transition: opacity 0.5s;
    }

    &.shrink {
        border-radius: 50%;
        width: 3.5rem;
        height: 3.5rem;
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;
        span {
            animation: 0.5s $bezier forwards submit_span_1;
        }

    }

    &.spin {
        border-top: 2px $primary solid;
        animation: 1s $bezier-spinner submit_2;
        animation-iteration-count: infinite;
    }

    &.fill {
        border-left:  2px $primary solid;
        border-right:  2px $primary solid;
        border-bottom:  2px $primary solid;
        animation: 0.5s forwards ease-out submit_3;
    }

    &.check {
        animation: none;
        background-color: $primary;
        border: 0;
        transform: rotate(-45deg);
        &:before {
            animation: 0.4s 0.5s forwards linear submit_4;
        }
    }

    &.shrink,
    &.spin,
    &.fill,
    &.check {
        pointer-events:none;
        appearance: none;
    }
}
