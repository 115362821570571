//*------------------------------------*\
//    $SMALL BREAKPOINT
//*------------------------------------*/

.project {
	.module-content {
		overflow: hidden;
		padding: 0;
		
		.project-details {
			color: $grayText;
			background-color: white;
    		border: $default-module-side-padding solid $gray;
			padding: calc(#{$default-module-side-padding} * 2);
			width: 100%;
	
			img { 
				width: 100%;
				margin: 0;
				border: calc(#{$default-module-side-padding-half} / 6) solid $grayDarkest;
			}

			.headline {
				color: $grayText;
			}

			.project-links {
				display: flex;
	        	flex-direction: row;
	        	flex-wrap: wrap;
	        	justify-content: center;
	        	max-width: 100%;
	        	margin-top: 2rem;
	        	
	        	.project-link {
	        		margin-right: 1rem;
	        		margin-bottom: 1rem;
	        		
					&:after {
						display: none;
					}
					
					&:last-of-type {
						margin-right: 0;
					}

					a {
						color: $grayText;
						font-size: calc(#{$scale09} + 1vw);
						text-decoration: none;
					}

					.mls {
						@include isVisuallyHidden();
					}
				}
				
				@media screen and (orientation: portrait) {
					justify-content: flex-start;
				}
	        	
	        	
	        }
		}
	
		.headline {
			font-size: $scale06;
			margin-bottom: 2rem;
		}
		
		.body-copy {
			background: $grayText;
			padding: calc(#{$default-module-side-padding} * 2);
			width: 100%;
			font-size: $scale05;
			line-height: $scale06;
			overflow: hidden;
			
			p {
				line-height: 1.3;
				margin-bottom: 1rem;
				
				&:last-of-type {
					margin-bottom: 0;
				}
			}
			
			img { 
				width: 20vw;
				float: left;
				margin: 1rem 2rem;
			}
		}
	}
}