// // DO NOT EDIT DIRECTLY!
////Generated by gulpfile.js/tasks/iconFont.js
////from gulpfile.js/tasks/iconFont/template.sass

@font-face {
  font-family: icons;
  src: url("../fonts/icons.eot");
  src: url("../fonts/icons.eot?#iefix") format('embedded-opentype'), url("../fonts/icons.woff") format('woff'), url("../fonts/icons.ttf") format('truetype'), url("../fonts/icons.svg#icons") format('svg');
  font-weight: normal;
  font-style: normal; }

@mixin icon($content: '') {
  &:before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    content: $content;
    font-family: 'icons';
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    speak: none;
    text-transform: none;
    @content; } }

[class^="icon-"], [class*=" icon-"] {
  @include icon; }

// Expose as class
.icon-amazon:before {
  content: "\EA01"; }

// Expose as class
.icon-bandcamp:before {
  content: "\EA02"; }

// Expose as class
.icon-instagram:before {
  content: "\EA03"; }

// Expose as class
.icon-itunes:before {
  content: "\EA04"; }

// Expose as class
.icon-soundcloud:before {
  content: "\EA05"; }

// Expose as class
.icon-spotify:before {
  content: "\EA06"; }

// Expose as class
.icon-youtube:before {
  content: "\EA07"; }


