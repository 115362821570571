//*------------------------------------*\
//    $MEDIUM BREAKPOINT
//*------------------------------------*/

@media screen and (min-width: '#{$medium-breakpoint}px') {
	.bio {
		.module-content {
			.body-copy {
				strong {
					@include body-copy-headline($scale05);
					font-weight: bold;
				}
				
				p {
					font-size: $scale05;
				}
			
				time {
					font-size: $scale05;
				}
			}
		}
	}
}
