//*------------------------------------*\
//    $LARGE BREAKPOINT
//*------------------------------------*/

@media screen and (min-width: '#{$large-breakpoint}px') {
	.statement {
		.module-content {
			.headline {
				font-size: $scale09;
			}
		
			.body-copy {
				 font-size: $scale07;
			}
		}
	}
}
